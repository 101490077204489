<template>
  <div class="mediaContainer">
    <b-container class="shlajsna">
    <b-row>
      <b-col cols="12" class="mb-4">          
        <h1 class="text-center">{{ langCategory.name }}</h1>
      </b-col>
    </b-row>

    <b-row v-if="video.length > 0" class="mb-5">
      <b-col cols="12">
        <h2 class="text-center mb-4">{{ $t('videos.download-from-video-library') }}</h2>
      </b-col>
        <b-col cols="12">
        <b-overlay :show="videoOverlay">
          <MediaGrid :media="video" />
          <b-row v-if="videoLoadMore === true">
            <b-col>
              <p class="text-center">
                <b-button variant="primary" @click="loadMoreVideoData" class="my-4" size="lg">
                {{ $t('general.load-more') }} ({{ (videoPage + 1) + "/" + totalPagesVideo }})
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-overlay>
        </b-col>
    </b-row>

    <b-row v-if="audio.length > 0"  class="mb-5">
      <b-col cols="12">
          <h2 class="text-center">{{ $t('audio.discover-our-audio-library') }}</h2>
      </b-col>
      <b-col cols="12">
        <b-overlay :show="audioOverlay">
          <MediaAudioGrid :media="audio" />
          <b-row v-if="audioLoadMore === true">
              <b-col>
                <p class="text-center">
                  <b-button variant="primary" @click="loadMoreAudioData" class="my-4" size="lg">
                  {{ $t('general.load-more') }} ({{ (audioPage + 1) + "/" + totalPagesAudio }})
                  </b-button>
                </p>
              </b-col>
            </b-row>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row v-if="images.length > 0"  class="mb-5">
      <b-col cols="12">
        <h2 class="text-center mb-4">{{ $t('images.download-from-image-library') }}</h2>
      </b-col>
        <b-col cols="12">
        <b-overlay :show="imageOverlay">
          <MediaGrid :media="images" />
          <b-row v-if="imageLoadMore === true">
            <b-col>
              <p class="text-center">
                <b-button variant="primary" @click="loadMoreImageData" class="my-4" size="lg">
                {{ $t('general.load-more') }} ({{ (imagePage + 1) + "/" + totalPagesImage }})
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-overlay>
        </b-col>
    </b-row>
    </b-container>
  </div>
</template>
<script>
import MediaGrid from '@/components/util/MediaGrid.vue'
import MediaAudioGrid from '@/components/util/MediaAudioGrid.vue'

export default {
    name: "CategoryMedia",
    components : { MediaGrid, MediaAudioGrid },
    data: function() {
        return {
            user: {},
            video: [],
            videoPage: 0,
            totalPagesVideo: 0,
            videoOverlay: false,
            videoLoadMore: false,

            images: [],
            imagePage: 0,
            totalPagesImage: 0,
            imageOverlay: false,
            imageLoadMore: false,
            
            audio: [],
            audioPage: 0,
            totalPagesAudio: 0,
            audioOverlay: false,
            audioLoadMore: false,
            category: {},
            langCategory: {},
            langCategoryId:  this.$route.params.categoryId
        }
    },
    created: async function() {
      this.setData();
    },

    methods: {
      loadMoreVideoData: function() {
        this.videoOverlay = true;
        this.videoPage++;
        this.$http.get('/api/media/lang/' + this.$root.lang.IANA + '/category/' + this.category.id + '/type/video/?page='  + this.videoPage).then(r => {
          this.video = this.video.concat(r.data.content);
          this.videoLoadMore = r.data.hasNext;
        }).catch(e => {
          this.handleError(e);
        });
        this.videoOverlay = false;
      },

      loadMoreImageData: function() {
        this.imageOverlay = true;
        this.imagePage++;
        this.$http.get('/api/media/lang/' + this.$root.lang.IANA + '/category/' + this.category.id  + '/type/image/?page=' + this.imagePage).then(r => {
          this.images = this.images.concat(r.data.content);
          this.imageLoadMore = r.data.hasNext;
        }).catch(e => {
          this.handleError(e);
        });
        this.imageOverlay = false;
      },

      loadMoreAudioData: function() {
        this.audioOverlay = true;
        this.audioPage++;
        this.$http.get('/api/media/lang/' + this.$root.lang.IANA + '/category/' + this.category.id  + '/type/audio/?page=' + this.audioPage).then(r => {
          this.audio = this.images.concat(r.data.content);
          this.audioLoadMore = r.data.hasNext;
        }).catch(e => {
          this.handleError(e);
        });
        this.audioOverlay = false;
      },

      async setData() {
        await this.$http.get("/api/tag/getbyname/" + this.langCategoryId).then(r => {
          for (let i = 0; i < r.data.names.length; i++) {
            this.category = r.data;
            const tag = r.data.names[i];
            if (this.$root.lang.IANA === tag.language.IANA) {
              this.langCategory = tag;
            }
          }
        }).catch(e => {
          this.handleError(e);
        });
        this.videoOverlay = true;
        await this.$http.get('/api/media/lang/' + this.$root.lang.IANA + '/category/' + this.category.id + '/type/video/?page=0').then(r => {
          this.video = r.data.content;
          this.videoLoadMore = r.data.hasNext;
          this.totalPagesVideo = r.data.totalPages;
        }).catch(e => {
          this.handleError(e);
        });
        this.videoOverlay = false;

        this.imageOverlay = true;
        await this.$http.get('/api/media/lang/' + this.$root.lang.IANA + '/category/' + this.category.id + '/type/image/?page=0').then(r => {
          this.images = r.data.content;
          this.imageLoadMore = r.data.hasNext;
          this.totalPagesImage = r.data.totalPages;
        }).catch(e => {
          this.handleError(e);
        })
        this.imageOverlay = false;

        this.audioOverlay = true;
          await this.$http.get('/api/media/lang/' + this.$root.lang.IANA + '/category/' + this.category.id + '/type/audio/?page=0').then(r => {
            this.audio = r.data.content;
            this.audioLoadMore = r.data.hasNext;
            this.totalPagesAudio = r.data.totalPages;
          }).catch(e => {
            this.handleError(e);
          })
        this.audioOverlay = false;
      }

    },
    computed: {
      _lang: function() {
        return this.$root.lang
      }
    },
    watch:{
      _lang : async function() {
        this.setData();
      }
    }
}

</script>
<style></style>
